<template>
<div>
    <CCol sm="12">
        <CCard>
            <CCardBody class="d-flex flex-column">
                    <div role="group" class="form-group">
                        <ImageCrop ref="imageCrop" v-model="imageData" @fileChanged="fileChanged" :ratio="1/1" ratioDisplay="1:1" v-bind:class="{'is-invalid': isImageValid == false }"></ImageCrop>
                        <!-- Name -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Name') }}</label>
                            <input type="text" v-model="sponsors.name" class="form-control" :class="{'is-invalid': submitted && $v.sponsors.name.$error  }"/>
                            <div v-if="submitted && !$v.sponsors.name.required" class="invalid-feedback">Sponsor name is required</div>
                        </div>
                        <!-- Link -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Link') }}</label>
                            <input type="text" v-model="sponsors.link" class="form-control" :class="{ 'is-invalid': submitted && $v.sponsors.link.$error }"/>
                            <div v-if="submitted && !$v.sponsors.link.required" class="invalid-feedback">Link is required</div>
                        </div>
                        <!-- Level -->
                        <div class="mt-2 mb-2" >
                            <label class=""> {{ ('Level') }}</label>
                            <input  type="number" placeholder="1 , 2 , 3 , 4" v-model="sponsors.level" class="form-control" :class="{ 'is-invalid': submitted && $v.sponsors.level.$error }"/>
                            <div class="invalid-feedback" v-if="!$v.sponsors.level.between">Must be between {{$v.sponsors.level.$params.between.min}} and {{$v.sponsors.level.$params.between.max}}</div>
                            <div v-if="submitted && !$v.sponsors.level.required" class="invalid-feedback">Level is required</div>                       
                        </div>
                    </div>
                <!-- Action button + loading -->  
                <CSpinner v-if="loading" variant="success" small class="align-self-end"></CSpinner>
                <CButton v-else type="button" class="btn btn-primary align-self-end" @click="submitClicked">{{ ('Accept changes') }}</CButton>
            </CCardBody>
        </CCard>
    </CCol>
    <ConfirmationPopUp :confirmationMessage="this.confirmationMessage" @closeConfirmRequested="onClickConfirmationPopup" v-model="showConfirmationPopup"></ConfirmationPopUp>
</div>
</template>
 
<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import ImageCrop from "../controls/ImageCrop.vue";
import { mapState } from 'vuex'
import ConfirmationPopUp from "@/views/controls/popups/ConfirmationPopUp.vue";
import router from '@/router/index';
import InputSponsors from "@/classes/InputSponsors";

import {
  required,
  between 
} from 'vuelidate/lib/validators'

  export default {
  components: { ConfirmationPopUp, ImageCrop },
    props:{    
         baseSponsors:
        {
            type: Object,
            default: null,
        }
    },
    data()
    {
        return {
            imageData: '',
            selectedFile: '',
            submitted: false,
            sponsors: {name:"",link:"",level: ''},
            loading: false,
            showConfirmationPopup: false,
            confirmationMessage:"",

        }
    },
        validations: {
                sponsors: {
                    name: { required },
                    link: { required },
                    level: { required,between: between(1, 4) }
                   
                },
            
        },
    mounted: function()
    {
        this.resetSponsors();
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errorOutput: function()
        {
            var result = [];

            if(this.alertType == 'alert-danger' && this.alertOutput && Array.isArray(this.alertOutput))
            {
                result = this.alertOutput.reduce(function(accum, currentVal) {
                accum[currentVal.key] = currentVal.value;
                    return accum;
                }, {});
            }

            return result;
        },
        ...mapState('sponsors', ['processTask']),

        isImageValid: function(){
        return this.imageValidator() && this.errorValidator('image');
        },
    },
    methods:{
        resetSponsors(){

            if(this.baseSponsors){

                this.sponsors = UTILS_HELPER.deepClone(this.sponsors, this.baseSponsors);
                console.log(this.sponsors.photo);
                this.imageData = process.env.VUE_APP_ROOT + this.sponsors.photo;


            }

        },
        fileChanged(val)
            {
            this.selectedFile = val;
            },

        submitClicked(){
            this.submitted = true;
            
             this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } 
            // Emit event
            if(this.sponsors)
            {
                this.loading = true;

                 var id = this.baseSponsors && this.$route.params.id ? this.$route.params.id : null;
                 var input = new InputSponsors(id, this.sponsors, this.selectedFile);

                this.$emit('submit-requested', input);
            }
        },

        onClickConfirmationPopup(value){
            this.showConfirmationPopup = value;     
            router.push('/sponsors/list');
        },

        errorValidator(fieldName)
        {
        var result = undefined;

        if(this.alertOutput && Array.isArray(this.alertOutput)) // Error alertOutput
        {
            if(this.alertOutput.find(obj => {
                return obj.key === fieldName
            }))
            {
                result = false;
            }
        }

        return result;
        },

        imageValidator(){
            var result = undefined;

            if(this.submitted && !this.imageData)
            {
                result = false;
            }

            return result;
        },
    },
    watch:
    {
        processTask: function(val)
        {
            if(val)
            {
                this.showConfirmationPopup = true;
                this.confirmationMessage = "Data saved successfully!"

            }
        },
       
        baseSponsors: function()
        {
            this.resetSponsors();
        },

        alertType: function()
        {
            this.loading = false;
        },
        alertOutput: function()
        {
            this.loading = false;

            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        }
    }
  }
</script> 
 
<style scoped>

    /deep/div.is-invalid > .base-image-input > span {
        background-color: palevioletred;
    }

    .base-image-input {
        display: block;
        width: 200px;
        height: 200px;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
    }

    .showDropdown
    {
        display: block !important;
    }

    .input-group-append
    {
      height: fit-content;
    }

    img
    {
        width: 50%;
        height: 50%;
    }

</style> 