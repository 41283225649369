<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{('Edit sponsor') }}</h2>
    <FormSponsors :baseSponsors="sponsors" @submit-requested="submitRequested"></FormSponsors>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormSponsors from '../forms/FormSponsors.vue'
import router from '@/router';

  export default {  
    data () {
    return {
      sponsors: null
    }
    
  },
  components: { FormSponsors },
    computed:{
      ...mapState('sponsors', ['listOutput']),
    },
    methods: {
      ...mapActions('sponsors', ['editSponsors']),
      ...mapActions('sponsors', ['getList']),
      submitRequested (sponsors) {
        this.editSponsors(sponsors);
      },
    },
    mounted: function()
    {
      if(!this.$route.params.sponsors)
      {
        router.push({ path: '/sponsors/list' });
      }
      else
      {
        this.sponsors = this.$route.params.sponsors;
      }
    }
  }
</script> 
 
<style scoped>
</style> 