export default class InputSpeakers
{
    constructor(id,spons,photo) 
        {
    // TODO Validation

        if(id)
        {
        this.id = id;
        }
        
        this.spons = spons;
        this.photo = photo;
       
    }      
}